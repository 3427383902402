<template>
  <main>
    <h3>Investments</h3>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-else class="error">&nbsp;</div>
    <div v-if="investments">
      <div class="row" v-for="investment in investments" :key="investment.id">
        <router-link
          class="offering-link"
          :to="{
            name: 'InvestmentReceipt',
            params: {
              offeringId: investment.offeringId,
              investmentId: investment.id
            }
          }"
        >
          <div class="row single border-light">
            <div class="col-md-3">{{ investment.investmentName }}</div>
            <div class="name col-md-3">
              {{ investment.offeringName }}
            </div>
            <div class="name col-md-1">
              ${{ numberWithCommas(investment.totalInvestment) }}
            </div>
            <div v-if="investment.status == 'C2MError'" class="name col-md-1">
              pending
            </div>
            <div v-else class="name col-md-1">
              {{ investment.status }}
            </div>

            <div class="name col-md-3">
              {{ investment.createdAt.toDate().toLocaleString() }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="text-center" v-if="investments && investments.length == 0">
        <p>
          This is where your investments will be listed once you invest in an
          offering.
        </p>
      </div>
    </div>
  </main>
  <Footer v-if="investments" />
</template>

<script>
import getCollection from "@/composables/getCollection";
import Footer from "@/components/navigation/Footer.vue";
import getUser from "@/composables/getUser.js";
import usePrettyNumbers from "@/composables/usePrettyNumbers";

export default {
  components: { Footer },
  setup() {
    const { user } = getUser();
    const { error, documents: investments } = getCollection(
      "investments",
      [["investorId", "==", user.value.uid]],
      ["createdAt", "desc"]
    );

    const { numberWithCommas } = usePrettyNumbers();

    return {
      numberWithCommas,
      investments,
      error
    };
  }
};
</script>

<style>
</style>